
/* Container holding the image and the text */
.bg-image {
background-image: url("./headerimg.jpg");
background-size: cover;
background-position: top;

  }
  .name{
    font-size: 1.75rem;
    font-weight: 300;
    margin-left: 140px;
    margin-top: -25px;
  }
 .test{
  display: flex;
  flex-direction: column;
  justify-content: center;
 }
 .btn-contact{
  background-color: #ff914e;
  color: white;
 }
  /* Bottom left text */
  .bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  }
  
  /* Top left text */
  .top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  }
 
  .typical-header{
    color: black;
    font-weight: 900;
  }
  
  @media screen and (max-width:600px){
    .display-1{
      padding-top: 2rem;
      font-size: 3rem;
    }
    .name{
      padding-top: 1rem;
      font-size: 1.4rem;
      font-weight: 200;
      margin-left: 10px;
      
    }
    .justify-content-middle{
      text-align: center;
    }
    .hide{
      display: none;
    }
} 