@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body {
    background: #f2f2f2;
    font-family: 'Poppins', sans-serif;
}

 .box {
   
 
    cursor: pointer;
    
    transition: all 0.5s ease-out;
}

.box:hover {
   box-shadow: 0 0 6px #4183D7;
}

.card-height{
    min-height: 22rem;
}



.img-thumbnail1 {
   border: none;
    max-width: 15%;
    height: auto;
    margin: auto;
}
