.container.testimonials_container{
 
  padding-bottom: 4rem;
}
.client_avatar{
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variant);

}
.testimonial-header{
  margin-top: 9rem;
}
.testimonial{
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;

}
.testimonial1{
  background-color: #fbb67b;
}
.client_review{
  color: var(--color-light);
  font-weight: 300;
  display: block;
  margin: 0.8rem auto 0;
  font-size: 24px;
}
/* ############################## Swiper Js *###################### */

.swiper-pagination-clickable .swiper-pagination-bullet{
  background: black;
}

/* ############################## Swiper Js *######################*/

/*########################### Media Quries (Medium Devices) #########################  */
@media screen and (max-width:1024px){
  .container.testimonials_container{
      width: 60%;
  }
}
   
   /*########################### Media Quries (Small Devices) #########################  */
  @media screen and (max-width:600px){
  .container.testimonials_container{
 width: var(--container-width-sm);
     
  } 
  .client_review{
      width: var(--container-width-sm);
  }
}