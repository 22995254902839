@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
body{
    font-family: 'Poppins', sans-serif;
    background-color: #f6f6f2;
    line-height: 1.7; 
}
  *{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;   
}
.box1{min-height:100vh;}
h1,h2,h3,h4,h5{
  font-weight: 500;
}
:root {
  --bgcolor: #f1f5fd;
  --primary-color: #6f5de7;
  --text-color: #828286;
  --main-text-color: #333333;

}
a{
  color: var(--color-primary);
  transition:var(--transition);
}
a:hover{
  color: var(--color-white);
}
img{
  display: block;
  width: 100%;
  object-fit: cover;
  }
  .justify-content-middle{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #myBtn {
     /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #FBB67B; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 10px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 12px; /* Increase font size */
    width: 42px;
  }
  #myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }