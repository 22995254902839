.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;

}
.portfolio_item{
    
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid black;
    transition: var(transition);
}
.portfolio_item:hover{
border-color: var(--color-primary-variant);
background: transparent;
}
.portfolio_item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}
.card-height1{
    height: 18rem;
    
}
.portfolio_item h3{
    margin: 1.2rem 0 2rem;
}
.justify-content-middle1{
    margin-top: 4.5rem;
    margin-bottom: 6rem;
}
.porifolio_item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
 /*########################### Media Quries (Medium Devices) #########################  */
 @media screen and (max-width:1024px){
    .portfolio_container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;

    }
    } 
     /*########################### Media Quries (Small Devices) #########################  */
    @media screen and (max-width:600px){
        .portfolio_container{
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
        }
      
    } 